import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import { GuideInfo } from "../components/utils/Model"
import { getGuides } from "../components/utils/Guide"
import CrossList from "../components/guides/CrossList"
import highlightCode from "../components/utils/HighlightCode"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        guidesRef: Array<string>
      }
    }
    guides: {
      nodes: Array<{
        description: string
        name: string
        path: string
        title: string
        type: string
      }>
    }
  }
}

// markup
const QuickstartTemplate = (props: Props) => {
  React.useEffect(() => {
    highlightCode()
  })
  const crossGuides = getGuides(
    props.data.guides.nodes,
    props.data.page.frontmatter.guidesRef
  )
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className="quickstart"
    >
      <div className="extra">
        <img className="img-light" src="/img/extra/quickstart.svg" alt="" />
        <img className="img-dark" src="/img/extra/quickstart-dark.svg" alt="" />
      </div>
      <div className="container">
        <div style={{ maxWidth: 900 }} className="py-6">
          <div>
            <Link to={"/guides"} className="button is-white is-white-spring is-button-back">
              <FontAwesomeIcon className="icon" icon={faAngleLeft} />
              All guides
            </Link>
          </div>
          <HTMLContent
            content={props.data.page.html}
            className={"markdown links-animate content"}
          ></HTMLContent>

          <hr className="mt-6" />
          <div className="markdown content pb-6">
            <h2>Next, try these popular guides</h2>
            <p className="mb-6">
              You've already seen how simple Spring can be, but it's also very
              flexible. There are thousands of things you can do with Spring,
              and we have lots of guides available to take you through the most
              popular choices. Why not keep on learning and try one of these
              additional guides?
            </p>
            <div className="pt-2">
              <CrossList items={crossGuides} />
            </div>
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default QuickstartTemplate

// graphQL queries
export const pageQuery = graphql`
  query QuickstartTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        guidesRef
      }
    }
    guides: allApiGuide {
      nodes {
        description
        name
        title
        type
        path
      }
    }
  }
`
