import { Link } from "gatsby"
import * as React from "react"

interface Props {
  items: Array<{
    path: string
    decription: string
    title: string
  }>
}

const Item = ({ item }: any) => {
  return (
    <article className="column">
      <Link to={item.path} className="box is-special border-dark">
        <h2 className="is-size-6 my-1 py-0">{item.title}</h2>
        <div className="is-size-6 my-1 py-0">{item.description}</div>
      </Link>
    </article>
  )
}

const CrossList = ({ items }: Props) => {
  return (
    <div className="columns cross-list">
      {items?.map((item: any, index) => (
        <Item key={`item-${index}`} item={item} />
      ))}
    </div>
  )
}

export default CrossList
